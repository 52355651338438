<template>
  <base-section
    id="k-d-a-contact-us"
    class="black"
    space="12"
  >
    <v-container>
      <v-row
        align="center"
        justify="space-between"
      >
        <v-col
          cols="12"
          md="9"
          lg="10"
        >
          <component
            :is="item.strComponent"
            v-for="(item, i) in m_arrContents"
            :key="i"
            :class="getTextClasses(item.eType, ['justify-start', 'white--text'])"
          >
            {{ item.strText }}
          </component>
        </v-col>

        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <base-btn
            color="white"
            :to="{ name: 'KDAContactUs' }"
          >
            {{ m_strButtonLabel }}
          </base-btn>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import TextClass from '@/mixins/text-class'

  export default {
    name: 'KDAContactUs',

    mixins: [TextClass],

    data: () => ({
      m_strButtonLabel: 'Contact Us',
    }),

    computed: {
      m_arrContents () {
        return [
          {
            strText: 'HAVE A QUESTION? LET US KNOW!',
            strComponent: 'h1',
            eType: this.eContentType.TITLE_3,
          },
          {
            strText: 'We will get back to you within 1-2 business days',
            strComponent: 'h6',
            eType: this.eContentType.SUBTITLE_3,
          },
        ]
      },
    },
  }
</script>
